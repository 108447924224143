@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  height: 100%;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:-webkit-any-link {
  color: #919191;
}

/*If put into the style.ts it is supposed to affect, it does not override the default color for some reason. */
.MuiInput-underline::after {
  border-color: #000078;
}